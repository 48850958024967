@font-face {
	font-family: 'SuisseIntl';
	src: url('/assets/froda/fonts/SuisseIntl-Regular.otf') format('opentype');
	font-weight: 400; /* Regular weight */
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SuisseIntl';
	src: url('/assets/froda/fonts/SuisseIntl-RegularItalic.otf') format('opentype');
	font-weight: 400; /* Regular weight */
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SuisseIntl';
	src: url('/assets/froda/fonts/SuisseIntl-Book.otf') format('opentype');
	font-weight: 500; /* Book weight */
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SuisseIntl';
	src: url('/assets/froda/fonts/SuisseIntl-BookItalic.otf') format('opentype');
	font-weight: 500; /* Book weight */
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SuisseIntl';
	src: url('/assets/froda/fonts/SuisseIntl-Medium.otf') format('opentype');
	font-weight: 600; /* Medium weight */
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SuisseIntl';
	src: url('/assets/froda/fonts/SuisseIntl-MediumItalic.otf') format('opentype');
	font-weight: 600; /* Medium weight */
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'CentraNo2';
	font-style:  normal;
	font-weight: 700;
	src: url("/assets/yabie/fonts/CentraNo2-Bold.woff") format("woff");
}

@font-face {
	font-family: 'CentraNo2';
	font-style:  normal;
	font-weight: 500;
	src: url("/assets/yabie/fonts/CentraNo2-Medium.woff") format("woff");
}

@font-face {
	font-family: 'Swedbank Sans';
	font-style:  normal;
	font-weight: 400;
	src: url("/assets/swedbank-pay/fonts/SwedbankSans-Regular.woff") format("woff");
}

@font-face {
	font-family: 'Swedbank Sans';
	font-style:  normal;
	font-weight: 500;
	src: url("/assets/swedbank-pay/fonts/SwedbankSans-Medium.woff") format("woff");
}

@font-face {
	font-family: 'Swedbank Sans';
	font-style:  normal;
	font-weight: 700;
	src: url("/assets/swedbank-pay/fonts/SwedbankHeadline-Bold.woff") format("woff");
}

@font-face {
	font-family: 'Sharp Sans';
	font-style:  normal;
	font-weight: 700;
	src: url("/assets/lunar/fonts/SharpSansBold.woff") format("woff");
}

@font-face {
	font-family: 'Sharp Sans';
	font-style:  normal;
	font-weight: 400;
	src: url("/assets/lunar/fonts/SharpSans.woff") format("woff");
}

@font-face {
	font-family: 'Aeonik';
	font-style:  normal;
	font-weight: 700;
	src: url("/assets/billy/fonts/AeonikBold.ttf") format("truetype");
}

@font-face {
	font-family: 'Aeonik';
	font-style:  normal;
	font-weight: 400;
	src: url("/assets/billy/fonts/AeonikRegular.ttf") format("truetype");
}

@font-face {
	font-family: 'Brix Sans';
	font-style:  normal;
	font-weight: 700;
	src: url("/assets/kontist/fonts/BrixSansBold.woff") format("woff");
}

@font-face {
	font-family: 'Brix Sans';
	font-style:  normal;
	font-weight: 400;
	src: url("/assets/kontist/fonts/BrixSansRegular.woff") format("woff");
}

@font-face {
	font-family: 'Ubuntu';
	font-style:  normal;
	font-weight: 700;
	src: url("/assets/visma/fonts/Ubuntu-Bold.ttf") format("truetype");
	font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/visma/fonts/OpenSans-VariableFont.ttf") format("ttf supports variations");
  font-weight: 100 1000;
}
