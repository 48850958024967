@import 'fonts';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
    .h-128 {
        height: 32rem;
    }
	.min-h-40 {
		min-height: 10rem;
	}
	.min-h-80 {
		min-height: 20rem;
	}
    .min-h-128 {
        min-height: 32rem;
    }
}

// Applies to all modules
app-onboarding,
app-error-page,
app-tailwind-library,
app-my-froda,
app-signup,
app-login,
app-contract,
app-white-label-client,
app-partner-portal {

	@apply font-body;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	small {
		margin: 0;
	}

	h1,
	h2,
	h3,
	h4 {
		@apply font-header;
	}

	h1 {
		@apply text-2xl;
	}

	h2 {
		@apply text-xl;
	}

	h3 {
		@apply text-lg;
	}

	h4 {
		@apply text-base;
	}

	p, div {
		@apply font-normal;
		@apply text-base;
		font-variant-numeric: tabular-nums;
	}

	p{
		@apply text-paragraph;
	}

	small {
		@apply text-sm;
		@apply text-paragraph;
	}

	a {
		color: inherit;
		@apply text-black;
		@apply cursor-pointer;
		@apply font-medium;
	}

	.link {
		@apply text-black;
		@apply tracking-wide;
		@apply transition-colors;
		@apply ease-in-out;
		@apply duration-150;
		@apply cursor-pointer;
	}

	.text-underline {
		@apply tracking-wide;
		@apply transition-colors;
		@apply ease-in-out;
		@apply duration-150;
		@apply cursor-pointer;
		text-decoration: underline;
        text-underline-offset: 2px;
        text-decoration-thickness: 1px;
	}

	.link-underline {
		@apply text-black;
		@apply tracking-wide;
		@apply transition-colors;
		@apply ease-in-out;
		@apply duration-150;
		@apply cursor-pointer;
		text-decoration: underline;
        text-underline-offset: 2px;
        text-decoration-thickness: 1px;


		a {
			@apply text-black;
			@apply cursor-pointer;
			@apply font-medium;
			text-decoration: underline;
			text-underline-offset: 2px;
			text-decoration-thickness: 1px;
		}
	}
}

// Applies to all modules except app-white-label-client
app-onboarding,
app-error-page,
app-tailwind-library > .theme-froda,
app-my-froda,
app-signup,
app-login,
app-contract,
app-partner-portal {

	@apply text-almost-black;

	h1,
	h2,
	h3,
	h4 {
		@apply font-medium;
		@apply text-almost-black;
	}

	button{
		@apply font-normal;
	}

}

// Applies to app-white-label-client module
app-white-label-client,
app-tailwind-library > :not(.theme-froda) {
	[type='checkbox']:focus {
		--tw-ring-color: none;
	}

	@apply text-paragraph;

	h1,
	h2,
	h3,
	h4 {
		@apply font-bold;
		@apply text-title;
	}

	p {
		@apply font-body;
		@apply font-normal;
	}

	button{
		@apply font-bold;
	}
}

app-white-label-client > .theme-billy {
	button{
		@apply font-normal;
	}
}
